@import url('https://use.typekit.net/rql3oin.css');

* {
  font-family: proxima-nova, sans-serif;
}

p.input-label {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding-left: 2px;
}

select {
  border: 2px solid #c5ced6;
  box-sizing: border-box;
  box-shadow: 2px 10px 40px rgba(20, 50, 182, 0.01);
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
}
select:focus {
  border: 2px solid #2849d6;
  outline: none;
}
select::placeholder {
  color: #8792a2;
  font-weight: 300;
}
select.block {
  width: 100%;
}

.progress-playing {
  color: #1aae9f !important;
}
.progress-recording {
  color: #d3455b !important;
}

/* Buttons */
.button {
  display: inline-flex;
  padding: 12px 5px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border: 0;
  transition: 0.2s all linear;
  max-height: 17px;
  cursor: pointer;
}
.button.default {
  background: #d3455b;
  color: white;
}
.button.recording {
  background: #d3455b;
  color: white;
}
.button.playing, .button.recorded {
  background: #1aae9f;
  color: white;
}
.button svg {
  margin: 0 8px 0 0;
}
.button img.svg {
  height: 30px;
  width: 30px;
}
.button.disabled,
.button:disabled {
  background: #e7e9ec;
  color: #8792a2;
  pointer-events: none;
}
